import { Container } from '@/components';
import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import styles from './styles.module.scss';
import Image from 'next/image';
import { Swiper, SwiperSlide, SwiperClass } from 'swiper/react';
import clsx from 'clsx';
import { Pagination } from 'swiper/modules';
import { useTranslation } from 'next-i18next';

type CarouselItem = {
  src: string;
  width: number;
  height: number;
};

const group: CarouselItem[] = [
  { src: '/images/home/customersCarousel/rangeteller.webp', width: 153, height: 26 },
  { src: '/images/home/customersCarousel/moblers.webp', width: 123, height: 40 },
  { src: '/images/home/customersCarousel/talentedge.webp', width: 164, height: 30 },
  { src: '/images/home/customersCarousel/rangeteller.webp', width: 153, height: 26 },
];

export const CustomersCarouselSection: React.FC = () => {
  const { t } = useTranslation('home', { keyPrefix: 'companies' });

  return (
    <Box component="section" className={styles.section}>
      <Container>
        <Typography variant="h3" className={styles.title}>
          {t('title')}
        </Typography>
        <Box className={styles.carouselWrapper}>
          <SliderDesktop />
          <SliderMobile />
        </Box>
      </Container>
    </Box>
  );
};

const SliderDesktop: React.FC = () => {
  return (
    <Box className={clsx(styles.customersDesktop, 'carouselDesktop')}>
      {group.map(({ src, width, height }, index) => (
        <Box key={src + index}>
          <Image src={src} width={width} height={height} alt="" />
        </Box>
      ))}
    </Box>
  );
};

const SliderMobile: React.FC = () => {
  const [swiper, setSwiper] = useState<SwiperClass>();
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const onPaginationClick = (index: number): void => {
    swiper?.slideTo(index);
  };

  return (
    <Box className="carouselMobile">
      <Swiper
        slidesPerView={1}
        modules={[Pagination]}
        onSwiper={(swiper: SwiperClass): void => setSwiper(swiper)}
        onRealIndexChange={(swiper) => {
          setActiveIndex(swiper.realIndex);
        }}
      >
        {group.map(({ src, width, height }, index) => {
          return (
            <SwiperSlide key={index}>
              <Box className={styles.sliderItemWrapper}>
                <Image src={src} width={width} height={height} alt="" />
              </Box>
            </SwiperSlide>
          );
        })}
        {!!swiper && (
          <CarouselPagination
            length={group.length}
            activeIndex={activeIndex}
            onClick={onPaginationClick}
          />
        )}
      </Swiper>
    </Box>
  );
};

const CarouselPagination: React.FC<{
  length: number;
  activeIndex: number;
  onClick(i: number): void;
}> = ({ length, activeIndex, onClick }) => {
  return (
    <Box className={styles.pagination}>
      {Array.from({ length }).map((_, index) => (
        <Box
          key={index}
          className={clsx({
            [styles.active]: activeIndex === index,
            [styles.semiactive]: activeIndex === index - 1 || activeIndex === index + 1,
          })}
          onClick={() => onClick(index)}
        />
      ))}
    </Box>
  );
};
